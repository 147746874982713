




























import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import SimpleBar from 'simplebar'
import Slide from '@/mixins/Slide.ts'
import MediaContent from '@/components/MediaContent.vue'
import Accordion from '@/components/Accordion.vue'
import Markdown from '@/components/Markdown.vue'
import ScrollerElement, { ScrollerElementAnimationEvent } from '@/components/ScrollerElement.vue'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import gsap from 'gsap'
import { resolveSlug } from '@/utils/alias-resolver'

gsap.registerPlugin(ScrollToPlugin)

@Component({
    components: {
        ScrollerElement,
        MediaContent,
        Accordion,
        Markdown
    }
})
export default class SlideDropdown extends mixins(Slide) {
    simpleBar!: SimpleBar
    realSlug = resolveSlug(this.walker)

    $refs!: {
        root: HTMLElement
        background: MediaContent
        accordion: Accordion
    }

    mounted(): void {
        this.simpleBar = new SimpleBar(this.$refs.root)
    }

    get walkerItem() {
        return this.walker.item as SlideWalkerItemDropdown
    }

    get backgroundMedia(): RoadizDocument | undefined {
        return this.walkerItem.backgroundMedia?.[0]
    }

    populateAppear(timeline: GSAPTimeline) {
        if (this.$refs.background) {
            timeline.from(
                this.$refs.background.$el,
                {
                    opacity: 0.8,
                    scale: 1.2,
                    duration: 2.4,
                    ease: 'power2'
                },
                0.5
            )
        }
    }

    populateEnterY({ timeline }: ScrollerElementAnimationEvent) {
        if (this.$refs.background) {
            timeline.fromTo(
                this.$refs.background.$el,
                {
                    opacity: 0.8,
                    scale: 1.2
                },
                {
                    opacity: 1,
                    scale: 1,
                    duration: 1,
                    ease: 'none'
                },
                0
            )
        }

        timeline.fromTo(
            this.$refs.accordion.$el,
            {
                opacity: 0
            },
            {
                opacity: 1,
                duration: 1,
                ease: 'none'
            },
            0
        )
    }

    onAccordionActiveItemChange({ element }: { element: HTMLElement }) {
        gsap.to(this.simpleBar.getScrollElement(), {
            scrollTo: element
        })
    }
}
