












































import { Component, Prop, Ref, Watch } from 'vue-property-decorator'
import Vue from 'vue'
import IconMore from '@/assets/img/icons/more.svg?sprite'
import IconLess from '@/assets/img/icons/less.svg?sprite'
import Markdown from '@/components/Markdown.vue'
import ButtonIcon from '@/components/ButtonIcon.vue'
import { Getter, namespace, State } from 'vuex-class'
import ModuleNamespace from '@/constants/module-namespace'
import MutationRemoteType from '@/constants/mutation-remote-type'
import { RemoteSlide } from '@/@types/store'

@Component({
    components: {
        IconMore,
        IconLess,
        Markdown,
        ButtonIcon
    }
})
export default class Accordion extends Vue {
    @Prop() datas!: Array<object>
    @Prop() slideId!: string
    @Prop() remoteEnabled!: boolean

    @State isLeader!: boolean
    @(namespace(ModuleNamespace.REMOTE).State('slides')) remoteSlides!: Array<RemoteSlide>

    @Getter navigationIsAllowed!: boolean

    @Ref() items!: Array<HTMLElement>

    isActive = -1

    toggleOpen(index: number): void {
        this.isActive = this.isActive === index ? -1 : index
    }

    formatIndex(index: number): string {
        return index < 10 ? '0' + index : '' + index
    }

    beforeEnter(element: HTMLElement) {
        element.style.height = '0'
        element.style.opacity = '0'
    }

    enter(element: HTMLElement): void {
        element.style.height = element.scrollHeight + 'px'
        element.style.opacity = '1'
    }

    afterEnter() {
        if (this.navigationIsAllowed) return

        const element = this.items[this.isActive]

        if (element) this.$emit('active-item-change', { element })
    }

    leave(element: HTMLElement): void {
        element.style.height = '0'
        element.style.opacity = '0'
    }

    beforeLeave(element: HTMLElement) {
        element.style.height = element.scrollHeight + 'px'
        element.style.opacity = '1'
    }

    onItemClick(index: number) {
        if (!this.navigationIsAllowed) return

        this.toggleOpen(index)
    }

    @Watch('isActive')
    onIsActiveChange() {
        if (!this.isLeader) return

        this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.SINGLE_SLIDE, {
            id: this.slideId,
            accordionItemIndex: this.isActive
        } as RemoteSlide)
    }

    @Watch('remoteSlides')
    onRemoteSlidesChange() {
        if (this.isLeader || !this.remoteEnabled) return

        const slide = this.remoteSlides.find(slide => slide.id === this.slideId)

        if (!slide || typeof slide.accordionItemIndex === 'undefined') return

        this.isActive = slide.accordionItemIndex
    }
}
